
<!-- Page Wrapper -->
<div id="wrapper">

  <!-- Sidebar -->
  <ul *ngIf="currentUserValue()" class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ocultar-print" [class.toggled]="toggled" id="accordionSidebar">

    <!-- Sidebar - Brand -->
    <a class="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
      <div class="sidebar-brand-icon">
        <img class="img-profile img-fluid" src="assets/img/logo-mas.png">
      </div>
      <div class="sidebar-brand-text mx-3">Dashboard</div>
    </a>

    <!-- Divider -->
    <hr class="sidebar-divider my-0">

    <!-- Nav Item - Dashboard -->
    <li class="nav-item" *ngIf="currentUser.rol !== 'dev'">
      <a class="nav-link" routerLink="central">
        <i class="fas fa-globe-americas" aria-hidden="true"></i>
        <span>Central</span></a>
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider">

    <!-- Heading -->
    <div class="sidebar-heading">
      Planillas
    </div>

    <!-- Nav Item - Pages Collapse Menu -->
    <li class="nav-item" *ngIf="currentUser.rol === 'admin' || currentUser.rol === 'supervisor' || currentUser.rol === 'tdp' || currentUser.rol === 'dev'">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#UsosContainer" aria-expanded="true"
         aria-controls="UsosContainer">
        <i class="fas fa-id-card"></i> <span> Usos </span>
      </a>
      <div id="UsosContainer" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Agrupar por uso:</h6>
          <a class="collapse-item" routerLink="/usos/por-eps" *ngIf="currentUser.staff">Por Validador</a>
         <!-- <a class="collapse-item" routerLink="/usos/por-dispositivo" *ngIf="currentUser.staff">Por idDispositivo</a> -->
          <a class="collapse-item" routerLink="/usos/por-empresa" *ngIf="currentUser.staff">Por Empresa</a>
          <a   *ngIf="currentUser.rol === 'admin' || currentUser.rol === 'supervisor' || currentUser.rol === 'tdp' || currentUser.rol === 'dev'" class="collapse-item" routerLink="/usos/por-bus">Por Bus</a>
          <a   *ngIf="currentUser.rol === 'admin' || currentUser.rol === 'supervisor' || currentUser.rol === 'tdp'" class="collapse-item" routerLink="/usos/por-itinerarios">Por Itinerarios</a>
          <a   *ngIf="currentUser.rol === 'admin' || currentUser.rol === 'supervisor' || currentUser.rol === 'tdp'" class="collapse-item" routerLink="/usos/por-itinerarios-detallado">Por Itinerarios Detallado</a>
        </div>
      </div>
    </li>

     <!-- Nav Item - Utilities Collapse Menu -->
     <li class="nav-item" *ngIf="currentUser.staff || currentUser.rol === 'admin'">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#TrasbordoContainer"
         aria-expanded="true" aria-controls="TrasbordoContainer">
         <i class="fas fa-bus-alt"></i> <span> Trasbordo </span>
      </a>
      <div id="TrasbordoContainer" class="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <a class="collapse-item" routerLink="/trasbordo/por-empresa" *ngIf="currentUser.staff">Por Empresa</a>
          <a class="collapse-item" routerLink="/trasbordo/por-bus" *ngIf="currentUser.rol === 'admin' || currentUser.staff">Por Bus</a>
          <a class="collapse-item" routerLink="/trasbordo/por-itinerarios" *ngIf ="currentUser.rol === 'admin' || currentUser.staff">Por Itinerarios</a>
        </div>
      </div>
    </li>

    <!-- Nav Item - Pages Collapse Menu -->
    <li class="nav-item" *ngIf="currentUser.rol === 'tdp' || currentUser.rol === 'admin'">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#PorHoraContainer" aria-expanded="true"
         aria-controls="PorHoraContainer">
        <i class="fa fa-clock"></i> <span> Por Hora </span>
      </a>
      <div id="PorHoraContainer" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Agrupar por hora:</h6>
          <a class="collapse-item" routerLink="/por-hora/por-eps" *ngIf="currentUser.staff">Por Validador</a>
          <!-- <a class="collapse-item" routerLink="/por-hora/por-validador-activo" *ngIf="currentUser.staff">Por Validador Activo</a> -->
          <a class="collapse-item" routerLink="/por-hora/por-empresa" *ngIf="currentUser.staff">Por Empresa</a>
          <a class="collapse-item" routerLink="/por-hora/por-bus">Por Bus</a>
          <a class="collapse-item" routerLink="/por-hora/comparar-por-hora" *ngIf="currentUser.staff">Comparar por Hora</a>
        </div>
      </div>
    </li>

    <!-- Nav Item - Pages Collapse Menu -->
    <li class="nav-item" *ngIf="currentUser.rol === 'admin' || currentUser.rol === 'tdp'">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#PorDiaContainer" aria-expanded="true"
         aria-controls="PorDiaContainer">
        <i class="fas fa-calendar-day"></i> <span> Por día </span>
      </a>
      <div id="PorDiaContainer" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Agrupar por día:</h6>
          <a class="collapse-item" routerLink="/por-dia/ingresos" *ngIf="currentUser.staff">Ingresos</a>
          <a class="collapse-item" routerLink="/por-dia/eventos" *ngIf="currentUser.staff">Eventos</a>
          <a class="collapse-item" routerLink="/por-dia/por-eps" *ngIf="currentUser.staff">Por validador</a>
          <a class="collapse-item" routerLink="/por-dia/por-empresa" *ngIf="currentUser.staff">Por empresa</a>
          <a class="collapse-item" routerLink="/por-dia/por-empresa-comparar" *ngIf="currentUser.staff">Comparar por Empresa</a>
          <a class="collapse-item" routerLink="/por-dia/por-bus">Por Bus</a>
       <!--   <a class="collapse-item" routerLink="/por-dia/por-tipo-bus" *ngIf="currentUser.staff">Por tipo de Bus</a>-->
        </div>
      </div>
    </li>

    <li class="nav-item" *ngIf="currentUser.staff">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#CompareContainer" aria-expanded="true"
         aria-controls="CompareContainer">
         <i class="fas fa-clone"></i> <span> Comparar </span>
      </a>
      <div id="CompareContainer" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
         
          <a class="collapse-item" routerLink="/compare/recargas" *ngIf="currentUser.staff">Recargas</a>
       
       <!--   <a class="collapse-item" routerLink="/por-dia/por-tipo-bus" *ngIf="currentUser.staff">Por tipo de Bus</a>-->
        </div>
      </div>
    </li>

    <!-- <li class="nav-item" *ngIf="currentUser.rol === 'admin' || currentUser.rol === 'tdp'">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#PorSemanaContainer" aria-expanded="true"
         aria-controls="PorSemanaContainer">
        <i class="fas fa-calendar-day"></i> <span> Por semana </span>
      </a>
      <div id="PorSemanaContainer" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Agrupar por semana:</h6>
          <a class="collapse-item" routerLink="/por-semana/por-semana-empresa-comparar" *ngIf="currentUser.staff">Uso por Empresa</a>
          <a class="collapse-item" routerLink="/por-semana/semana-por-eps" *ngIf="currentUser.staff">Por validador</a>

        </div>
      </div>
    </li> -->
    <!-- <li class="nav-item" *ngIf="currentUser.rol === 'admin' || currentUser.rol === 'tdp'">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#PorMesContainer" aria-expanded="true"
         aria-controls="PorMesContainer">
        <i class="fas fa-calendar-day"></i> <span> Por mes </span>
      </a>
      <div id="PorMesContainer" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Agrupar por mes:</h6>
          <a class="collapse-item" routerLink="/por-mes/por-mes-empresa-comparar" *ngIf="currentUser.staff">Uso por Empresa</a>
        </div>
      </div>
    </li> -->

    <!-- Nav Item - Utilities Collapse Menu -->
    <li class="nav-item" *ngIf="currentUser.staff || currentUser.rol === 'canal' || currentUser.rol === 'agencia'">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#RecargasContainer"
         aria-expanded="true" aria-controls="RecargasContainer">
        <i class="fas fa-money-bill-alt"></i> <span> Recargas<span *ngIf="!currentUser.staff"> y P.</span></span>
      </a>
      <div id="RecargasContainer" class="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Historial de recargas:</h6>
<!--          <a class="collapse-item" routerLink="/recargas/historial" *ngIf="currentUser.rol === 'canal'">Historial</a>-->
          <a class="collapse-item" routerLink="/recargas/todas" *ngIf="currentUser.staff">Directas</a>
          <a class="collapse-item" routerLink="/recargas/remotas" *ngIf="currentUser.staff">Remotas TDP</a>
          <a class="collapse-item" routerLink="/recargas/remotas-epas" *ngIf="currentUser.staff">Remotas EPAS</a>
          <a class="collapse-item" routerLink="/recargas/por-agencia"  *ngIf="currentUser.rol === 'canal' || currentUser.rol === 'agencia' || currentUser.staff">Por agencia</a>
          <a class="collapse-item" routerLink="/recargas/remotas-recargas"  *ngIf="currentUser.nombre === 'Antell'">Recargas Remotas</a>
          <a class="collapse-item" routerLink="/recargas/recargas-mas" *ngIf="currentUser.staff">Recargas Más</a>
        </div>
      </div>
    </li>

    <!-- Nav Item - Utilities Collapse Menu -->
    <li class="nav-item" *ngIf="currentUser.rol === 'admin' || currentUser.rol === 'tdp'">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#MonitorContainer"
         aria-expanded="true" aria-controls="MonitorContainer">
        <i class="fas fa-desktop"></i> <span> Monitoreo </span>
      </a>
      <div id="MonitorContainer" class="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">RTM:</h6>
          <a *ngIf="currentUser.rol === 'admin'" class="collapse-item" routerLink="/monitoreo/busesStatus">Buses</a>
          <a *ngIf="currentUser.rol === 'admin'" class="collapse-item" routerLink="/monitoreo/gps">GPS</a>
          <a *ngIf="currentUser.rol === 'tdp'" class="collapse-item" routerLink="/monitoreo/monitereogps">Monitoreo GPS-Empresa</a>
          <a *ngIf="currentUser.rol === 'tdp'" class="collapse-item" routerLink="/monitoreo/monitoreoidruta">Monitoreo Id Ruta</a>
        </div>
      </div>
    </li>

    <!-- Nav Item - Utilities Collapse Menu -->
    <li class="nav-item" *ngIf="currentUser.rol === 'admin' || currentUser.rol === 'tdp'">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#EstadoCuentaContainer"
         aria-expanded="true" aria-controls="EstadoCuentaContainer">
        <i class="fas fa-desktop"></i> <span> Estado de cuenta </span>
      </a>
      <div id="EstadoCuentaContainer" class="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Transacciones:</h6>
          <a class="collapse-item" routerLink="/estado-cuenta/por-empresa" *ngIf="currentUser.staff">Por empresa</a>
          <a  *ngIf="currentUser.rol === 'admin' || currentUser.rol === 'tdp'"class="collapse-item" routerLink="/estado-cuenta/por-bus">Por bus</a>
          <a   *ngIf="currentUser.rol === 'admin' || currentUser.rol === 'tdp'" class="collapse-item" routerLink="/estado-cuenta/comprobantes">Comprobantes</a>
          <a   *ngIf="currentUser.rol === 'admin' || currentUser.rol === 'tdp'" class="collapse-item" routerLink="/estado-cuenta/balance">Balance</a>
          <a class="collapse-item" routerLink="/estado-cuenta/resumen" *ngIf="currentUser.staff">Resumen</a>
        </div>
      </div>
    </li>

    <!-- Nav Item - Utilities Collapse Menu -->
    <div *ngIf="currentUser.rol !=='dev'">
    <div *ngIf="currentUser.rol !=='admin'">
    <li class="nav-item" *ngIf="currentUser.nombre !== 'Itau' || currentUser.staff || currentUser.rol === 'canal'">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#EstadoCanalContainer"
         aria-expanded="true" aria-controls="EstadoCanalContainer" *ngIf="currentUser.nombre !== 'Itau'"> 
        <i class="fas fa-bullhorn"></i> <span> Estado de canales </span>
      </a>
      <div id="EstadoCanalContainer" class="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <a class="collapse-item" routerLink="/estado-canal/por-canal" *ngIf="currentUser.staff">Por canal</a>
          <a class="collapse-item" routerLink="/estado-canal/comprobantes">Comprobantes</a>
          <a class="collapse-item" routerLink="/estado-canal/balance" *ngIf="currentUser.staff || currentUser.rol === 'canal'">Balance</a>
          <a class="collapse-item" routerLink="/estado-canal/monitoreo" *ngIf="currentUser.staff">Monitoreo</a>
          <a class="collapse-item" routerLink="/estado-canal/monitoreo-agencia" *ngIf="currentUser.rol === 'canal'">Monitoreo-Agencia</a>
          <a class="collapse-item" routerLink="/estado-canal/por-agencia" *ngIf="currentUser.staff || currentUser.rol === 'canal'">Por agencia</a>
          <!-- <a class="collapse-item" routerLink="/estado-canal/mapa" *ngIf="currentUser.staff">Mapa</a> -->
          <a class="collapse-item" routerLink="/estado-canal/resumen" *ngIf="currentUser.staff">Resumen</a>
          <a class="collapse-item" routerLink="/global/canales" *ngIf="currentUser.staff">Vista general</a>
        </div>
      </div>
    </li>
  </div>
</div>
    <!-- Nav Item - Utilities Collapse Menu -->
    <li class="nav-item" *ngIf="currentUser.staff">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#EstadoInteroperabilidadContainer"
         aria-expanded="true" aria-controls="EstadoInteroperabilidadContainer">
        <i class="fas fa-bullhorn"></i> <span> Estado Interoperabilidad </span>
      </a>
      <div id="EstadoInteroperabilidadContainer" class="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Compensación:</h6>
          <a class="collapse-item" routerLink="/estado-interoperabilidad/comprobantes">Comprobantes</a>
          <a class="collapse-item" routerLink="/estado-interoperabilidad/balance">Balance</a>
          <a class="collapse-item" routerLink="/estado-interoperabilidad/alphas">Alphas</a>
          <a class="collapse-item" routerLink="/estado-interoperabilidad/ganancia">Ganancias</a>
        </div>
      </div>
    </li>

    <li class="nav-item" *ngIf="currentUser.staff">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#ControlDeStockContainer"
         aria-expanded="true" aria-controls="ControlDeStockContainer">
        <i class="fas fa-bullhorn"></i> <span> Control De Stock </span>
      </a>
      <div id="ControlDeStockContainer" class="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Items:</h6>
          <a class="collapse-item" routerLink="/cds/tarjetas">Tarjetas</a>
        </div>
      </div>
    </li>

    <hr class="sidebar-divider" *ngIf="currentUser.staff">

    <!-- Nav Item - Tables -->
    <li class="nav-item" *ngIf="currentUser.staff">
      <a class="nav-link" routerLink="/global/tarjetathon">
        <i class="fa fa-university"></i>
        <span> TarjetaTon</span></a>
    </li>

    <!-- Nav Item - Tables -->
    <li class="nav-item" *ngIf="currentUser.staff">
      <a class="nav-link" routerLink="/global/libro-banco">
        <i class="fa fa-university"></i>
        <span> Libro Banco</span></a>
    </li>

    <!-- Nav Item - Tables -->
    <li class="nav-item" *ngIf="currentUser.staff">
      <a class="nav-link" routerLink="/global/informes">
        <i class="fas fa-file-medical-alt"></i>
        <span> Informes</span></a>
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider d-none d-md-block">

    <!-- Sidebar Toggler (Sidebar) -->
    <div class="text-center d-none d-md-inline">
      <button class="rounded-circle border-0" id="sidebarToggle" (click)="toggle()"></button>
    </div>

  </ul>
  <!-- End of Sidebar -->

  <!-- Content Wrapper -->
  <div id="content-wrapper" class="d-flex flex-column">

    <!-- Main Content -->
    <div id="content">

      <!-- Topbar -->
      <nav *ngIf="currentUserValue()" class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

        <!-- Sidebar Toggle (Topbar) -->
        <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3" (click)="toggle()">
          <i class="fa fa-bars"></i>
        </button>

        <!-- Topbar Navbar -->
        <ul class="navbar-nav ml-auto">

          <div class="topbar-divider d-none d-sm-block"></div>

          <!-- Nav Item - User Information -->
          <li class="nav-item dropdown no-arrow">
            <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{currentUser.nombre}} {{currentUser.apellido}}</span>
              <i class="fas fa-user"></i>
            </a>
            <!-- Dropdown - User Information -->
            <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
              <a class="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                Cerrar sesión
              </a>
            </div>
          </li>

        </ul>

      </nav>
      <!-- End of Topbar -->

      <router-outlet></router-outlet>

    </div>
    <!-- End of Main Content -->

  </div>
  <!-- End of Content Wrapper -->

</div>
<!-- End of Page Wrapper -->

<!-- Scroll to Top Button-->
<a class="scroll-to-top rounded" href="#page-top">
  <i class="fas fa-angle-up"></i>
</a>

<!-- Logout Modal-->
<div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">¿Seguro que desea salir?</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
        <button class="btn btn-primary" (click)="auth.logout()" data-dismiss="modal">Salir</button>
      </div>
    </div>
  </div>
</div>

<!-- Loading -->
<div class="my-overlay" *ngIf="storage.loading">
  <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
</div>
