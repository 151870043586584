import { NgModule } from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'central',
    pathMatch: 'full'
  },
  {
    path: 'central',
    loadChildren: () => import('./dash/dash.module').then(m => m.DashModule)
  },
  {
    path: 'cds',
    loadChildren: () => import('./cds/cds.module').then(m => m.CdsModule)
  },
  {
    path: 'usos',
    loadChildren: () => import('./usos/usos.module').then(m => m.UsosModule)
  },
  {
    path: 'por-hora',
    loadChildren: () => import('./por-hora/por-hora.module').then(m => m.PorHoraModule)
  },
  {
    path: 'por-dia',
    loadChildren: () => import('./por-dia/por-dia.module').then(m => m.PorDiaModule)
  },
  {
    path: 'compare',
    loadChildren: () => import('./compare/compare.module').then(m => m.CompareModule)
  },
  {
    path: 'por-semana',
    loadChildren: () => import('./por-semana/por-semana.module').then(m => m.PorSemanaModule)
  },
  {
    path: 'por-mes',
    loadChildren: () => import('./por-mes/por-mes.module').then(m => m.PorMesModule)
  },
  {
    path: 'estado-cuenta',
    loadChildren: () => import('./estado-cuenta/estado-cuenta.module').then(m => m.EstadoCuentaModule)
  },
  {
    path: 'estado-canal',
    loadChildren: () => import('./estado-canal/estado-canal.module').then(m => m.EstadoCanalModule)
  },
  {
    path: 'estado-interoperabilidad',
    loadChildren: () => import('./estado-interoperabilidad/estado-interoperabilidad.module').then(m => m.EstadoInteroperabilidadModule)
  },
  {
    path: 'recargas',
    loadChildren: () => import('./recargas/recargas.module').then(m => m.RecargasModule)
  },
  {
    path: 'detalles',
    loadChildren: () => import('./detalles/detalles.module').then(m => m.DetallesModule)
  },
  {
    path: 'monitoreo',
    loadChildren: () => import('./monitoreo/monitoreo.module').then(m => m.MonitoreoModule)
  },
  {
    path: 'global',
    loadChildren: () => import('./global/global.module').then(m => m.GlobalModule)
  },
  {
    path: 'trasbordo',
    loadChildren: () => import('./trasbordo/trasbordo.module').then(m => m.TrasbordoModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
