// export const environment = {
//   production: false,
//   PROTOCOL: 'http://',
//   CENTRAL_HOST: 'localhost:8002',
//   BUSES_HOST: 'localhost:8203',
//   CENTRAL_HOST_QA: 'central-qa.z1.mastarjeta.net',
//   CONTROL_HOST: 'localhost:8204',
// };
 export const environment = {
   production: false,
   PROTOCOL: 'https://',
   CONTROL_HOST: 'controldestock-qa.z1.mastarjeta.net',
   CENTRAL_HOST: 'central-qa.z1.mastarjeta.net',
   CENTRAL_HOST_QA: 'central-qa.z1.mastarjeta.net',
   BUSES_HOST: 'buses-qa.z1.mastarjeta.net'
 };
// export const environment = {
//   production: false,
//   PROTOCOL: 'http://',
//   CONTROL_HOST: 'dev.mastarjeta.net:8204',
//   CENTRAL_HOST: 'dev.mastarjeta.net:8201',
//   CENTRAL_HOST_QA: 'dev.mastarjeta.net:8201',
//   BUSES_HOST: 'dev.mastarjeta.net:8203'
// };
