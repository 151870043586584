import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public loading = false;
  public loading_mas = false;
  public empresas: Empresa[] = [];
  public empresaSelected = 0;
  public canales: Canal[] = [];
  public canalSelected = 0;
  public agencias: Agencia[] = [];
  public agenciaSelected = 0;
  public cache: any = {};
  public vaciar: boolean = true;
  public cambiarVaciar: Subject<boolean> = new Subject<boolean>();

  public addDays(date, days): Date {
    const nueva = new Date(Number(date));
    nueva.setDate(date.getDate() + days);
    return nueva;
  }

  constructor() {
    this.cambiarVaciar.subscribe((value) => {
      this.vaciar = value;
    })
  }

  public cambiarEstado() {
    this.cambiarVaciar.next(this.vaciar);
  }

  public setItem(key: string, value: any): void {
    this.cache[key] = value;
    localStorage.setItem(key, JSON.stringify(value));
  }

  public getItem(key: string): any {
    return this.cache[key];
  }

  public setFechaDesde(fechaStr: string): void {
    this.cache[`fechaDesde`] = fechaStr;
    this.setItem('fechaDesde', fechaStr);
  }

  public setFechaHasta(fechaStr: string): void {
    this.cache[`fechaHasta`] = fechaStr;
    this.setItem('fechaHasta', fechaStr);
  }
}

class Empresa {
  nombre: string;
  // tslint:disable-next-line:variable-name
  empresa_id: number;
}

class Canal {
  nombre: string;
  // tslint:disable-next-line:variable-name
  id: number;
}

class Agencia {
  nombre: string;
  // tslint:disable-next-line:variable-name
  id: number;
  canal: Canal;
}
