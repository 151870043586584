import { Component } from '@angular/core';
import {AuthService} from './auth/auth.service';
import {StorageService} from './utils/storage.service';
import {User} from './auth/models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  toggled = false;
  currentUser: User;
  constructor(
    public auth: AuthService,
    public storage: StorageService,
  ) {
    this.auth.currentUser.subscribe(data => this.currentUser = data);
    const hoy = new Date();
    this.storage.setFechaDesde((hoy.toISOString()).slice(0, 10));
    this.storage.setFechaHasta((hoy.toISOString()).slice(0, 10));
  }

  toggle(): void {
    this.toggled = !this.toggled;
  }

  currentUserValue(): any {
    return this.auth.currentUserValue;
  }
}
